import React, { useEffect } from "react";
import axios from 'axios';


const getSortedLessonsArray = (lessonsString)=> {

    let lessonsArray = JSON.parse(lessonsString);

    if(lessonsArray !== null) {
  
      for (var i in lessonsArray) {
          lessonsArray[i].point = parseFloat(lessonsArray[i].point);
        }
      
        lessonsArray = lessonsArray.sort(((a,b)=>a.point-b.point));
    }
  
      return lessonsArray;
}
  

  export default getSortedLessonsArray;