import React, { useEffect, useState, Fragment } from "react"
import Layout from "../../components/layout"
import Post from "../../components/posts/post"
import PostFull from "../../components/posts/post-full"
import PostsGroup from "../../components/posts/posts-group"
import LoadingSpinner from '../../components/loadingSpinner'
import loadLessonsInLocalStorage from '../../services/loadLessonsInLocalStorage';
import axios from 'axios';
import NotFoundPage from '../../pages/404';
import isLoggedIn from "../../services/auth"
import clearLocalStorage from "../../services/clearLocalStorage"
import SEO from "../../components/seo"

import * as pageStyles from "../../scss/productItemPage.module.scss"
import * as postsGroupStyles from "../../components/posts/postsGroup.module.scss"
import Pagination from "../../components/pagination"

import getSortedLessonsArray from '../../services/getSortedLessonsArray';

const ProductsPage = ({ id, uri }) => {

    console.log('The id is', id)
    
    const [singleLesson, setSingleLesson] = useState('');
    const [show404, setShow404] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loggedIn, setLoggedIn] = useState('')


    const getNextThreeLessons = (id, sortedLessonsArray) => {
        let indexCurrentLesson = sortedLessonsArray.findIndex(element => element.id == id);
        return sortedLessonsArray.filter(element => sortedLessonsArray.indexOf(element) > indexCurrentLesson && sortedLessonsArray.indexOf(element) <= indexCurrentLesson + 3);
    }

    const fetchSingleLesson = (id) => {
        let token = localStorage.getItem("user");
        var data = JSON.stringify({
            "token": token,
            "id": id
        });

        var config = {
            method: 'post',
            url: 'https://detoxme.at/wp/wp-json/siegfried/v1/lesson/',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        return axios(config);
    }


    // // const init = () => {
    let nextThreeLessons = [];
    let lessonBeforeCurrent = '';
    let lessonAfterCurrent = '';
    if (typeof window !== "undefined") {

        let lessonsString = localStorage.getItem('lessons');
        if (!lessonsString) {
            loadLessonsInLocalStorage();
            lessonsString = localStorage.getItem('lessons');
        }
        let sortedLessonsArray = getSortedLessonsArray(lessonsString);
        if(sortedLessonsArray != null) {
            nextThreeLessons = getNextThreeLessons(id, sortedLessonsArray);
            
            let indexCurrentLesson = sortedLessonsArray.findIndex(element => element.id == id)
            let indexLessonBefore = indexCurrentLesson - 1;
            let indexLessonAfter = indexCurrentLesson + 1;
            lessonBeforeCurrent = '';
            lessonAfterCurrent = '';
            if (indexLessonBefore >= 0) {
                lessonBeforeCurrent = sortedLessonsArray[indexLessonBefore];
            }

            if (indexLessonAfter < sortedLessonsArray.length) {
                lessonAfterCurrent = sortedLessonsArray[indexLessonAfter]
            }
        }

       

    }

    const reset = () => {
        setLoading(true)
        setSingleLesson('');
    }
    
    useEffect(() => {

        isLoggedIn().then(loggedIn => {
            setLoggedIn(loggedIn)
            if (!loggedIn) {
                clearLocalStorage();
            }
        });

        fetchSingleLesson(id).then(
            (response) => {
                console.log('The response is', response)
                if (response.data.c == 200) {

                    setLoading(false)
                    setSingleLesson(response.data.data);
                    console.log("the single lesson",response.data.data);
                }
                else {
                    setShow404(true);
                }
            }
        ).catch(function (error) {
            console.log(error);
        });

    }, [uri])


    if (show404) {
        return (
            <NotFoundPage />
        )
    }

    if (loading) {
        return (
            <Layout>
                <LoadingSpinner 
                    containerHeight= "100vh" 
                    style={{marginTop:300}}
                />
            </Layout>
        )
    }

    return (
        <Layout>
            {singleLesson != '' &&
                <Fragment>
                    <SEO
                        title={singleLesson.title}
                        description = {singleLesson.description}
                        keywords="Detox, Abnehmen, Fasten, Online, Kurs, Entgiften,Entschlacken"
                        author= "spectory"
                    />
                    <div className={`container ${pageStyles.container}`}>
                        <Pagination style={{ marginTop: "40px" }} />
                        <PostFull item={singleLesson}
                            lessonAfterCurrent={lessonAfterCurrent}
                            lessonBeforeCurrent={lessonBeforeCurrent}
                            reset={reset}
                        />
                    </div>
                </Fragment>
                }

            {
                nextThreeLessons != '' &&
                <div className={`container ${pageStyles.container}`}>
                    <PostsGroup title="Nächste Module">
                        <div className={postsGroupStyles.threeItems}>
                            {nextThreeLessons.map((lesson)=> 
                            <div onClick={reset}>
                                <Post loggedIn={loggedIn} type="small" item={lesson} />    
                            </div>
                            )}
                        </div>
                    </PostsGroup>
                </div>

            }

        </Layout>
    )
}

export default ProductsPage
